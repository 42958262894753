import React from 'react';

const Services = () => {
  return (
    <div class="container">
      <div class="welcome">
        <h2>Our Services</h2>
        <p>This is where you can find information about our services.</p>
      </div>
    </div>
  );
}

export default Services;
