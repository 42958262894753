import React from 'react';

const Home = () => {
  return (
    <div class="container">
        <div class="welcome">
            <h2>Welcome to Car Rent Compare</h2>
            <p>This is the homepage of our application. Feel free to explore!</p>
        </div>
    </div>
  );
}

export default Home;
